@import 'tailwindcss/base';
@import '@radix-ui/themes/styles.css';

@tailwind components;
@tailwind utilities;

* {
	/* Evil side effect from tailwindcss/base.css, causes Radix Themes inputs to have an icky blue outline */
	--tw-ring-color: transparent !important;
}
